#faf-content {
  background: #ffffff;
}



#body {
  max-width: 1720px;
  width: 95%;
  font-size: 14px;
  font-family: Roboto, Sans-Serif;
  font-weight: 300;
  color: #494949;
  @media screen and (max-width: 1200px) {
    padding-top: 60px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}



#categories-menu {
  margin: 53px 40px 0 0;
  .category-list-title {
    margin: 0 0 20px 0;
    padding: 0;
    border: none;
  }

  .category-list-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 5px 12px;
    border-radius: 12px;
    text-transform: capitalize;
    font-weight: inherit;
    &.active {
      background: #efefef;
    }
    .active {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 400;
      color: inherit;
    }
    a {
      margin: 0 10px 0 0;
      font-size: inherit;
      line-height: 20px;
      padding: 0;
    }
    span {
      font-weight: 400;
    }
  }
}

#tags-menu {
  .tags-menu-title {
    margin: 0 0 20px 0;
    padding: 0;
    border: none;

  }
  .tags-menu {
    .tag-item {
      a {
        margin: 0 2px 10px 0;
        padding: 4px 10px;
        border-radius: 10px;
        font-size: 13px;
        line-height: 14px;
        background: #efefef;
        &:hover {
          background: #dadada;
        }
        &:after {
          display: none;

        }
      }
    }
  }
}

#show-all-tags {
  font-size: 16px;
  &:before {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    content: "";
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    border: solid #a3a3a3;
    border-width: 0 2px 2px 0;
    padding: 4px;
    top: -3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.show-all-tags-less {
    &:before {
      top: 2px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);

    }
  }
}

#search-form {
  .form-search-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;

    .search-global{
      display: none;
    }

    .search-field:first-child{
      width: 40%;
    }
    .form-group{
      margin-right: 30px;
      &:last-child{
        margin-left: auto;
        margin-right: 0;
      }
    }

    > div:first-child {
      @media screen and (max-width: 1100px){
        width: 100%;
      }
      #search-field-container {
        border-radius: 18px;
        border: solid 1px #cccccc;
        overflow: hidden;
        #search-btn {
          height: 36px;
          line-height: 36px;
          padding: 0 15px 0 2px;
          border: none;
          box-shadow: none;

        }
        .search-icon {
          border: none;
        }
        .help-block {
          display: none;
        }
      }

    }
  }
}

#topics {
  .btn {
    padding: 12px 20px;
    line-height: 20px;
  }
  .btn-cyan {
    //position: relative;
    //top: -6px;
    color: #fff;
    background-color: #3391c8;
    border-color: #3391c8;
  }
  #topics-content {
    .topic-h1 {
      h1 {
        font-family: "Roboto", "Arial", sans-serif;
        font-size: 28px;
        font-weight: 300;
      }
    }
    .topic-list-item {
      margin-top: 60px;
      margin-bottom: 40px;
      padding-top: 0;
      font-size: 14px;
      > .row > .col-md-3 .col-md-12 {
        font-family: "Roboto", "Arial", sans-serif;
        font-weight: 100;
        text-align: right;
      }
      h2 {
        a {
          font-family: "Roboto", "Arial", sans-serif;
          font-size: 24px;
          font-weight: 300;
          text-decoration: none;
          color: #3a3a3a;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .tags-links {
      font-size: 13px;
    }
    .rating-count {
      display: inline-block;
      position: relative;
      top: 4px;
    }
  }
}

//#search-form-sort,
//#search-form-per-page{
//  border: none;
//  box-shadow: none;
//}

.breadcrumb {
  border-radius: 18px;
}

.sort-dropdown-list,
.per-page-dropdown-list {
  .caret {
    display: inline-block;
    margin-left: 10px;
  }
  span {
    cursor: pointer;
  }
  ul {
    li {
      cursor: pointer;
      &.active {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
      &:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }
}

.form-search-control {

}

.topic-rating-row{
  display: none;
}
